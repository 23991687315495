import { useRef, useState } from "react";
import Slider from "react-slick";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TestimonialCard from "../TestimonialCard/TestimonialCard";
import "slick-carousel/slick/slick.css"; // Slick carousel CSS
import "slick-carousel/slick/slick-theme.css";
import  personAvatar from "../../assets/images/person-avatar.svg"
import kabilal from "../../assets/images/kabilal.jpeg"
import useMobile from "../../customHook/useMobile";
import "./style.css"



const testimonials = [
  {
    image: personAvatar,
    name: "Karmegam",
    category: "Category - Venue",
    content: "Hiring this team was a game-changer for my wedding! With the date fast approaching and no clear plan, I was feeling overwhelmed. But once I booked them, everything fell into place. They quickly organized my vendors, handled all the details, and made sure everything was on schedule. Their professionalism and expertise took all the stress out of planning, allowing me to enjoy the process and truly focus on the big day. I couldn't have asked for a better team to bring my wedding vision. Thank you MakeMyWedding team.",
  },
  {
    image: kabilal,
    name: "Kabilal",
    category: "Category - Makeup Artist",
    content: "Exceptional Service from MakeMyWedding! I recently had the pleasure of getting a makeover by Ezhil through MakeMyWedding, and I must say, the experience was outstanding! The final result - I looked and felt amazing! What impressed me the most was the quick turnaround time. Ezhil worked efficiently, completing the makeover in record time without compromising on quality. Thank you MakeMyWedding team.", 
  },
  {
    image: personAvatar,
    name: "Pavithra",
    category: "Category -  Makeup Artist",
    content: "Got a good support from MMW team ...I am happy to get a professional make up artist. Also after speaking with her have gained confidence too.",
  },
  {
    image: personAvatar,
    name: "Kartika",
    category: "Category -  Makeup Artist",
    content: "I'm very happy with the service provided by Makemywedding. They helped me find a great bridal makeup artist for my wedding in Tamil Nadu, despite my being based in Mumbai. The team was patient and understanding of my requirements, and also offered suggestions for other wedding services like mehendi and groom's makeover. Their assistance has made my wedding planning process much easier.",
  },
  {
    image: personAvatar,
    name: "Priyanka",
    category: "Category -  Makeup Artist",
    content: " I had an exceptional experience with the Make My Wedding service. They not only helped me find the best vendors for my big day but also secured everything at the most competitive prices. This service is truly pocket-friendly and makes it so easy to get high-quality wedding services without the usual stress. I would highly recommend Make My Wedding to anyone planning their wedding!",
  },
];


const Testimonial = () => {
  const sliderRef: any = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useMobile();

  const totalSlides = testimonials.length;
  const slidesToShow = isMobile ? 1 : 3; // Adjust based on your responsive settings

  const isFirstSlide = currentIndex === 0;
  const isLastSlide = currentIndex >= totalSlides - slidesToShow;

  const settings = {
    dots: isMobile,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    beforeChange: (current: any, next: any) => {
      setCurrentIndex(next);
    },
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Box sx={{ background: `linear-gradient(360deg, #FFFBFB 0%, #FFFFFF 50%, #FFF6F6 100%)` }}>
      <Box sx={{ paddingLeft: "6%", paddingRight: "6%", paddingTop: { md: "4%", xs: "10%" }, paddingBottom: { md: "4%", xs: "12%" } }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: { md: "3%", xs: "6%" } }}>
          <Typography sx={{ fontSize: { md: "40px", xs: "24px" }, fontWeight: "600", lineHeight: { md: "60px", xs: "32px" }, fontFamily: "Poppins !important", paddingBottom: { md: "0", xs: "3%" } }}>
            Our happy {isMobile && <br />} customers say!
          </Typography>
          {!isMobile && (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <IconButton
                onClick={() => sliderRef.current.slickPrev()}
                sx={{
                  zIndex: 10,
                  background: isFirstSlide ? "lightGrey" : "#FFFFFF",
                  boxShadow: "0px 0px 16px 0px #00000014",
                  pointerEvents: isFirstSlide ? "none" : "auto",
                }}
              >
                <ArrowBackIosIcon sx={{ paddingLeft: "4px" }} />
              </IconButton>
              <IconButton
                onClick={() => sliderRef.current.slickNext()}
                sx={{
                  zIndex: 10,
                  background: isLastSlide ? "lightGrey" : "#FFFFFF",
                  boxShadow: "0px 0px 16px 0px #00000014",
                  pointerEvents: isLastSlide ? "none" : "auto",
                }}
              >
                <ArrowForwardIosIcon sx={{ padding: "2px" }} />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box sx={{ width: "100%", marginTop: "2%" }}>
          <Slider ref={sliderRef} {...settings}>
            {testimonials?.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                image={testimonial?.image}
                name={testimonial?.name}
                content={testimonial?.content}
                category={testimonial?.category}
              />
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};


export default Testimonial;
