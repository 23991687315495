import { gql } from "@apollo/client";
import client from "./apolloClient";

let new_date = new Date();
new_date.setHours(new_date.getHours() + 5);
new_date.setMinutes(new_date.getMinutes() + 30);

// Define the mutation as a constant
const CREATE_ENQUIRY_MUTATION = gql`
    mutation CreateOneMakemywedding_enquiries(
        $data: Makemywedding_enquiriesCreateInput
    ) {
        createOneMakemywedding_enquiries(data: $data) {
            id
            name
            phone
            is_otp_verified
            created_at
            updated_at
            utm_source
            utm_medium
            utm_campaign
            adgroup
            placement
            device_model
            device
            match_type
            keyword
            campaign_id
        }
    }
`;

export const CREATE_MMW_CUSTOMER_QUERY = gql`
    mutation CreateOneMmw_customer_query($data: Mmw_customer_queryCreateInput) {
        createOneMmw_customer_query(data: $data) {
            id
        }
    }
`;

export const CREATE_MMW_CUSTOMER_TRACKING = gql`
    mutation CreateOneMmw_customer_tracking(
        $data: Mmw_customer_trackingCreateInput
    ) {
        createOneMmw_customer_tracking(data: $data) {
            id
        }
    }
`;

export const CREATE_MMW_CUSTOMER_FOLLOWUPS = gql`
    mutation CreateOneMmw_customer_followups(
        $data: Mmw_customer_followupsCreateInput
    ) {
        createOneMmw_customer_followups(data: $data) {
            id
        }
    }
`;

// Reusable function to call the mutation
export const createEnquiry = async (
    name: string,
    phone: string,
    isOtpVerified: boolean | number,
    utm_source: string,
    utm_medium: string,
    utm_campaign: string,
    adgroup: string,
    placement: string,
    device_model: string,
    device: string,
    match_type: string,
    keyword: string,
    campaign_id: string
) => {
    try {
        // Define the mutation variables
        const variables = {
            data: {
                created_at: new_date.toISOString(),
                is_otp_verified: isOtpVerified,
                name: name,
                phone: phone,
                updated_at: new_date.toISOString(),
                utm_source: utm_source,
                utm_medium: utm_medium,
                utm_campaign: utm_campaign,
                adgroup: adgroup,
                placement: placement,
                device_model: device_model,
                device: device,
                match_type: match_type,
                keyword: keyword,
                campaign_id: campaign_id,
            },
        };

        // Execute the mutation using Apollo Client
        const result = await client.mutate({
            mutation: CREATE_ENQUIRY_MUTATION,
            variables,
        });

        // Return the result or data as needed
        return result.data.createOneMakemywedding_enquiries;
    } catch (error) {
        console.error("Error creating enquiry:", error);
        throw new Error("Failed to create enquiry");
    }
};
