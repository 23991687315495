import React, { useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import success from "../../assets/images/success.svg"


const ThankyouCardForExisting: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {

  useEffect(() => {
    //executing first script
    const firstScript = document.createElement('script');
    firstScript.textContent = `gtag('event', 
    'conversion', {'send_to': 'AW-16756272083/hxarCMS41eIZENPXgbY-'});`
    firstScript.onload = () => { console.log("first script loaded") }
    document.head.appendChild(firstScript)
    //executing second script
    const secondScript = document.createElement('script');
    secondScript.textContent = `function gtag_report_conversion(url) {
 var callback = function () {
if (typeof(url) != 'undefined') {
window.location = url;
}
};
//  gtag('event', 'conversion', {
 gtag_report_conversion('event', 'conversion', {
'send_to': 'AW-16756272083/hxarCMS41eIZENPXgbY-',
'event_callback': callback
 });
 return false;
}`

    secondScript.onload = () => { console.log("second script loaded") }
    document.head.appendChild(secondScript)
    return () => {
      document.head.removeChild(firstScript)
      document.head.removeChild(secondScript)
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Add borderRadius to the Paper element
          margin: "unset",
          width: "352px",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "auto",
          width: "100%",
          maxWidth: "352px",
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={async () => {
            onClose();
          }}
          sx={{ position: "absolute", top: 8, right: 8, color: "#000000" }}
        >
          <CloseIcon />
        </IconButton>

        {/* Modal Content */}
        <Box sx={{ margin: "10% auto" }}>
          <DialogContent>
          <img src={success} alt="" style={{
            height:"70px",
            width:"70px",
            marginBottom:"10px"
          }} />
            <Typography
              variant="h6"
              textAlign="left"
              sx={{
                color: "#000000",
                fontFamily: "Poppins !important",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
           Already request submitted!</Typography>
            <Typography
              textAlign="left"
              mt={1}
              sx={{
                color: "#000",
                fontFamily: "Poppins !important",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
            We have already received your request.
            We will call you shortly           
             </Typography>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ThankyouCardForExisting;
