import { gql } from "@apollo/client";

export const VERIFY_OTP = gql`
    query LoginWithOTP($credential: OTPCredentialInput) {
        loginWithOTP(credential: $credential) {
            tokenInfo {
                accessToken
                expiresIn
                refreshToken
                refreshExpiresIn
                tokenType
                isValid
            }
        }
    }
`;
