import styled from "@emotion/styled";
import { TextField } from '@mui/material';

const CustomCssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "rgba(0, 0, 0, 0.87)",
borderColor: "rgba(0, 0, 0, 0.20)",
borderRadius:"8px",
fontFamily:"Poppins"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
borderColor: "rgba(0, 0, 0, 0.20)",
borderRadius:"8px",
fontFamily:"Poppins"
    },
    "&:hover fieldset": {
borderColor: "rgba(0, 0, 0, 0.20)",
    },
    "&.Mui-focused fieldset": {
borderColor: "rgba(0, 0, 0, 0.20)",
borderRadius:"8px",
fontFamily:"Poppins"
    },
    "& .MuiInputBase-input": {
      fontFamily: "Poppins",
      fontSize: "16px",
      opacity: 1,
      fontWeight: "500",
      borderRadius:"8px",
    },
  },
});


export default CustomCssTextField;