import { Box, Typography } from "@mui/material";
import "./style.css";
import React from "react";
import grt from "../../assets/images/grt.png"
import viveks from "../../assets/images/viveks.svg"
import palam from "../../assets/images/palam.png"
import poorvika from "../../assets/images/poorvika.png"
import gravity from "../../assets/images/gravity.png"


const partnerLogs = [grt, viveks, palam, poorvika, gravity];

const PartnersSection: React.FC = () => {
  return (
    <Box sx={{
      width: { xs: "100%", md: "100%" },
      marginTop: { xs: "10%", md: "4%" },
      backgroundColor: "#FFF5F5",
      // alignContent:"center"
    }}>
      <Box sx={{
        padding:{
          md:"5% 3% 4% 6%",
          xs:"6% 6% 6% 6%"
        }
       }}>
        <Box>
          <Typography
            variant="h2"
            sx={{
              color: "#000",
              fontFamily: "Poppins !important",
              fontSize: { xs: "24px", md: "40px" },
              fontStyle: "normal",
              fontWeight: { xs: "500", md: "600" },
              lineHeight: { xs: "32px", md: "44px" },
              padding: {
                md: "0 0 2% 0",
                xs: "6% 0 0 0",
              }
            }}
          >
            Partnered with best brands in the industry
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: "#000",
              fontFamily: "Poppins !important",
              fontSize: { xs: "14px", md: "20px" },
              fontWeight: "400",
              lineHeight: { xs: "21px", md: "30px" } /* 64px */,
              padding: {
                xs: "4% 0 4% 0",
                md: "0 0 1% 0",
              }
            }}
          >
            We partner with the top brands in your city to bring you exclusive discounts and deals across jewellery, <br /> apparels, electronics etc for all your wedding shopping needs
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap", // Allows wrapping to multiple rows
            gap: { xs: "20px", md: "30px" }, // Responsive gap
            marginTop: "2.5%",
            justifyContent: { xs: "center", md: "space-between" }, // Responsive alignment
            width:{
              md:"88%",
              xs:"unset"
            }
          }}
        >
          {partnerLogs.map((logo: string, index: number) => (
            <Box
              key={index}
              component="img"
              src={logo}
              alt={`Partner logo ${index + 1}`}
              sx={{
                width: { xs: "calc(50% - 10px)", md: "168px" }, // 2 columns on xs, fixed width on md+
                height: "104px", // Fixed height
                background: "#FFFFFF",
                borderRadius: "16px",
                border: "1px solid #FFE4E4",
                padding: "16px",
                objectFit: "contain", // Prevents stretching
                objectPosition: "center", // Centers the image
                overflow: "hidden", // Prevents overflow from container
                flexShrink: 0, // Prevents shrinking
              }}
            />
          ))}
        </Box>

      </Box>
    </Box>
  );
};

export default PartnersSection;
