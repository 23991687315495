import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import "./style.css";
import CustomCssTextField from "../CustomCssTextField/CustomCssTextField";
import OTPModal from "../OTPModal/OTPModal";
import ThankyouCardForExisting from "../ThankyouCardForExisting/ThankyouCardForExisting";
import { getEnquiryDataUsingMobileNumber } from "../../apollo/getEnquiryData";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import useMobile from "../../customHook/useMobile";
import { useLocation, useNavigate } from "react-router-dom";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit mobile number')
    .test('not-all-zeros', 'Mobile number cannot be all zeros', (value) => value !== '0000000000')
    .test('starts-with-valid-digit', 'Mobile number must start with a digit 6 or higher', (value: any) => {
      return value && parseInt(value[0], 10) >= 6;
    })
    .required('Mobile number is required'),
});

const EnterYourDetailsModal: React.FC<{
  open: boolean;
  onClos: () => void;
  setIsEditFormModalOpen?: any;
}> = ({ open, onClos, setIsEditFormModalOpen }) => {
  const [focusedNameE, setFocusedNameE] = useState(false);
  const [focusedMobileE, setFocusedMobileE] = useState(false);

  const [userName, setUserName] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");

  const [isFormModalOpen, setIsFormModalOpen] = useState(true);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [editForm, setEditForm] = useState({
    name: "",
    mobileNumber: ""
  })
  const navigate = useNavigate();
  const [isThankyouCardForExisting, setIsThankyouCardForExisting] =
    useState(false);
  const { createCustomerEventLog } = useCustomerEventLogs();
  const isMobile = useMobile();
  const location = useLocation();
  const [utmParams, setUtmParams] = useState({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    match_type: '',
    keyword: '',
    campaign_id: '',
    device: '',
    device_model: '',
    placement: '',
    adgroup: ''
  });

  const formikRef: any = useRef(null); // Reference to Formik instance

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues({ name: editForm?.name, mobile: editForm?.mobileNumber });
    }
  }, [editForm]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const source = searchParams.get('utm_source');
    const medium = searchParams.get('utm_medium');
    const campaign = searchParams.get('utm_campaign');
    const match_type = searchParams.get('Matchtype'); // Case-sensitive match
    const keyword = searchParams.get('Keyword');
    const campaign_id = searchParams.get('CampaignID');
    const device = searchParams.get('device');
    const device_model = searchParams.get('DeviceModel');
    const placement = searchParams.get('Placement');
    const adgroup = searchParams.get('adgroup');
    // Set all UTM parameters
    setUtmParams({
      utm_source: source || '',
      utm_medium: medium || '',
      utm_campaign: campaign || '',
      match_type: match_type || '',
      keyword: keyword || '',
      campaign_id: campaign_id || '',
      device: device || '',
      device_model: device_model || '',
      placement: placement || '',
      adgroup: adgroup || ''
    });
  }, [location.search]);


  const renderThankYou = () => {
    if (isMobile) {
      return (
        <ThankyouCardForExisting
          open={true}
          onClose={() => setIsThankyouCardForExisting(false)}
        />
      );
    } else {
      navigate("/request-already-submitted");
      return null; // Return null to avoid rendering anything
    }
  };


  const handleSubmit = async (values: any) => {
    createCustomerEventLog({
      event_name: `clicked_on_get_otp_${isMobile ? "mobile" : "desktop"}`,
      event_type: "click_event"
    })
    const existingData = await getEnquiryDataUsingMobileNumber(
      values.mobile.toString()
    );
    const isQueryExist = existingData?.mmw_customer_query?.length;
    const isPhoneVerified = existingData?.mmw_customer_contact?.[0]?.is_phone_verified
    if (existingData && isQueryExist && isPhoneVerified) {
      onClos();
      // Close the form modal and show thank you card for existing users
      setIsThankyouCardForExisting(true);
      setIsFormModalOpen(false); // Close form modal
    } else {
      onClos();
      // Set user details and open OTP modal
      setUserName(values.name);
      setUserMobileNumber(values.mobile.toString());
      setIsOtpModalOpen(true); // Open OTP modal
      setIsFormModalOpen(false); // Close form modal
      createCustomerEventLog({
        event_name: `otp_screen_open_${isMobile ? "mobile" : "desktop"}`,
        event_type: "click_event"
      })


    }
  };


  return (
    <>
      {isOtpModalOpen ? (
        <OTPModal
          open={isOtpModalOpen}
          onClose={() => setIsOtpModalOpen(false)}
          name={userName}
          mobileNumber={userMobileNumber}
          utmParams={utmParams}
          setIsEditFormModalOpen={setIsEditFormModalOpen}
          setEditForm={setEditForm}
        />
      ) : isFormModalOpen ? (
        <Dialog
          open={open}
          PaperProps={{
            sx: {
              borderRadius: "20px", // Add borderRadius to the Paper element
              margin: "unset",
              width: "auto",
              height: "auto"
            },
          }}
        >
          <Box
            sx={{
              height: "auto",
              width: "100%",
              maxWidth: "352px",
            }}
          >
            {/* Close button */}
            <Box sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}>
              <IconButton
                onClick={() => {
                  onClos();
                  setEditForm({
                    name: "",
                    mobileNumber: ""
                  })
                  createCustomerEventLog({
                    event_name: `enter_your_details_popup_closed_${isMobile ? "mobile" : "desktop"}`,
                    event_type: "click_event"
                  })
                }}
                sx={{
                  p: 2
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            {/* Modal Content */}
            <DialogContent
              sx={{ paddingTop: "0 !important" }}
            >
              <Typography
                variant="h6"
                textAlign="left"
                sx={{
                  color: "#000",
                  fontFamily: "Poppins !important",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}
              >
                To get FREE consultation,{isMobile && <br />} enter your details
              </Typography>
              <Formik
                innerRef={formikRef}
                initialValues={{
                  name: "",
                  mobile: ""
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  isValid,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                }) => (
                  <Form>
                    <DialogContent sx={{ padding: "0%" }}>
                      <CustomCssTextField
                        autoComplete="off"
                        onFocus={() => setFocusedNameE(true)}
                        onBlur={() => {
                          setFieldTouched("name", true);
                          setFocusedNameE(false);
                        }}
                        placeholder="Enter your name"
                        label={
                          focusedNameE || values.name ? "Enter your name" : ""
                        }
                        name="name"
                        fullWidth
                        margin="normal"
                        value={values.name}
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("name", value); // Use setFieldValue to update form state
                        }}
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      {touched.name && errors.name ? (
                        <Typography
                          sx={{
                            color: "#EF2965",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            height: "15px",
                          }}
                        >
                          {errors.name}
                        </Typography>
                      ) : (
                        <div style={{ height: "15px" }}></div>
                      )}
                      <CustomCssTextField
                        autoComplete="off"
                        onFocus={() => setFocusedMobileE(true)}
                        onBlur={() => {
                          setFieldTouched("mobile", true);
                          setFocusedMobileE(false);
                        }}
                        label={
                          focusedMobileE || values.mobile
                            ? "Enter your mobile number"
                            : ""
                        }
                        placeholder="Enter your mobile number"
                        name="mobile"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={values.mobile}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (/^\d*$/.test(value) && value.length <= 10) {
                            setFieldValue("mobile", value); // Use setFieldValue to update form state
                            if (value.length === 10) {
                              createCustomerEventLog({
                                event_name: `customer_entered_phone_number_${isMobile ? "mobile" : "desktop"}`,
                                event_type: "click_event"
                              })
                            }
                          }
                        }}
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          borderRadius: "8px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }}
                              >
                                +91
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched.mobile && errors.mobile ? (
                        <Typography
                          sx={{
                            color: "#EF2965",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            height: "15px",
                          }}
                        >
                          {errors.mobile}
                        </Typography>
                      ) : (
                        <div style={{ height: "15px" }}></div>
                      )}

                      <Box textAlign="center" mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            !isValid ||
                            !values.name.length ||
                            !values.mobile.toString().length
                          } // Disable if form is invalid or fields aren't touched
                          type="submit"
                          sx={{
                            fontFamily: "Poppins",
                            textTransform: "none",
                            display: "flex",
                            width: "100%",
                            height: "48px",
                            padding: "12px 24px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            flexShrink: 0,
                            borderRadius: "12px",
                            // border: `1px solid ${isValid &&
                            //   values.name.length &&
                            //   values.mobile.length
                            //   ? "#B37839"
                            //   : ""
                            //   }`, // Use different colors for valid/invalid
                            background:
                              isValid &&
                                values.name.length &&
                                values.mobile.toString().length
                                ? "#BE142D"
                                : "#E6E6E6", // Background color based on validity and touched state
                          }}
                        >
                          Get OTP
                        </Button>
                      </Box>
                    </DialogContent>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Box>
        </Dialog>
      ) : isThankyouCardForExisting ? (
        renderThankYou()
      ) : (
        <Dialog
          open={open}
          PaperProps={{
            sx: {
              borderRadius: "20px", // Add borderRadius to the Paper element
              margin: "unset",
              width: "352px",
            },
          }}
        >
          <Box
            sx={{
              height: "auto",
              width: "100%",
              maxWidth: "352px",
            }}
          >
            {/* Close button */}
            <Box sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              p: 1,
            }}>
              <IconButton
                onClick={() => {
                  onClos();
                  setEditForm({
                    name: "",
                    mobileNumber: ""
                  })
                  createCustomerEventLog({
                    event_name: `enter_your_details_popup_closed_${isMobile ? "mobile" : "desktop"}`,
                    event_type: "click_event"
                  })
                }}
                sx={{ p: 2 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            {/* Modal Content */}
            <DialogContent sx={{ paddingTop: "0 !important" }}>
              <Typography
                variant="h6"
                textAlign="left"
                sx={{
                  color: "#000",
                  fontFamily: "Poppins !important",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}
              >
                To get FREE consultation,{isMobile && <br />} enter your details
              </Typography>
              <Formik
                initialValues={{
                  name: editForm.name || "",
                  mobile: editForm.mobileNumber || ""
                }}

                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  isValid,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form>
                    <DialogContent sx={{ padding: "0%" }}>
                      <CustomCssTextField
                        autoComplete="off"
                        onFocus={() => setFocusedNameE(true)}
                        onBlur={() => {
                          setFieldTouched("name", true);
                          setFocusedNameE(false);
                        }}
                        placeholder="Enter your name"
                        label={
                          focusedNameE || values.name ? "Enter your name" : ""
                        }
                        fullWidth
                        margin="normal"
                        value={values.name}
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("name", value); // Use setFieldValue to update form state
                        }}
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      {touched.name && errors.name ? (
                        <Typography
                          sx={{
                            color: "#EF2965",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            height: "15px",
                          }}
                        >
                          {errors.name}
                        </Typography>
                      ) : (
                        <div style={{ height: "15px" }}></div>
                      )}
                      <CustomCssTextField
                        autoComplete="off"
                        onFocus={() => setFocusedMobileE(true)}
                        onBlur={() => {
                          setFieldTouched("mobile", true);
                          setFocusedMobileE(false);
                        }}
                        label={
                          focusedMobileE || values.mobile
                            ? "Enter your mobile number"
                            : ""
                        }
                        placeholder="Enter your mobile number"
                        name="mobile"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={values.mobile}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (/^\d*$/.test(value) && value.length <= 10) {
                            setFieldValue("mobile", value); // Use setFieldValue to update form state
                            if (value.length === 10) {
                              createCustomerEventLog({
                                event_name: `customer_entered_phone_number_${isMobile ? "mobile" : "desktop"}`,
                                event_type: "click_event"
                              })
                            }
                          }
                        }}
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          borderRadius: "8px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }}
                              >
                                +91
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched.mobile && errors.mobile ? (
                        <Typography
                          sx={{
                            color: "#EF2965",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            height: "15px",
                          }}
                        >
                          {errors.mobile}
                        </Typography>
                      ) : (
                        <div style={{ height: "15px" }}></div>
                      )}
                      <Box textAlign="center" mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            !isValid ||
                            !values.name.length ||
                            !values.mobile.toString().length
                          } // Disable if form is invalid or fields aren't touched
                          type="submit"
                          sx={{
                            fontFamily: "Poppins",
                            textTransform: "none",
                            display: "flex",
                            width: "100%",
                            height: "48px",
                            padding: "12px 24px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            flexShrink: 0,
                            borderRadius: "12px",
                            // border: `1px solid ${isValid &&
                            //   values.name.length &&
                            //   values.mobile.length
                            //   ? "#B37839"
                            //   : ""
                            //   }`, // Use different colors for valid/invalid
                            background:
                              isValid &&
                                values.name.length &&
                                values.mobile.toString().length
                                ? "#BE142D"
                                : "#E6E6E6", // Background color based on validity and touched state
                          }}
                        >
                          Get OTP
                        </Button>
                      </Box>
                    </DialogContent>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default EnterYourDetailsModal;
