import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import inverted from "../../assets/images/inverted.svg"

import "./style.css"

type TestimonialCardProps = {
  image: string;
  name: string;
  content: string;
  category: string;
};

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  image,
  name,
  content,
  category,
}) => {
  return (
    <Box className="card">
      <Box>
      <img src={inverted} alt="" style={{ height: "32px", width: "32px" }} />
      <Typography sx={{
        fontSize: "14px",
        fontWeight: "400",
        fontFamily: "Poppins !important",
        mt: 1,
        mb: 2
      }}>
        {content}
      </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <img src={image} alt="" style={{ height: "40px", width: "40px", borderRadius:"8px" }} />
        <Box>
          <Typography sx={{ fontSize: "16px", fontWeight: "500", fontFamily: "Poppins !important" }}>
            {name}
          </Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: "400", fontFamily: "Poppins !important" }}>
            {category}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialCard;
