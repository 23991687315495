import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import { FaqData } from "./FaqData";
import "./style.css";
import useMobile from "../../customHook/useMobile";

const FaqSection: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const isMobile = useMobile()

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box>
      <Box sx={{
        padding:{
          md:"4% 6% 0 6%",
          xs:"4% 6% 8% 6%"
        }
      }}>
        <Box
          sx={{
            height: "auto",
          }}
        >

          {/* FAQ Title */}
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: "#000",
                fontFamily: "Poppins !important",
                fontSize: { xs: "24px", md: "40px" },
                fontWeight: { xs: "500", md: "600" },
                lineHeight: "normal",
                fontStyle: "normal",
                padding:{
                  md:"0 0 0 0",
                  xs:"6% 0"
                }
              }}
            >
            Frequently asked{isMobile && <br/>} questions?
            </Typography>
          </Box>

          {/* FAQ Accordions */}
          <Box
            sx={{
              width: "100%",
              margin: "2% 0",
              borderRadius: "12px",
              background: "#FFF",
              boxShadow: {
                xs:"0px 6px 20px 0px rgba(0, 0, 0, 0.06)",
                md:"unset"
              },
            }}
          >
            {FaqData.map((faq, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index + 1}`}
                onChange={handleChange(`panel${index + 1}`)}
                sx={{ padding: {
                  xs:"1% 2%",
                  md:"0.5rem 0"
                } }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}bh-content`}
                  id={`panel${index + 1}bh-header`}
                >
                  <Typography
                    sx={{
                      flexShrink: 0,
                      color: "#000000",
                      fontFamily: "Poppins !important",
                      fontSize:
                        expanded === `panel${index + 1}`
                          ? { xs: "12px", md: "16px" }
                          : { xs: "12px", md: "16px" },
                      // fontWeight: expanded === `panel${index + 1}` ? 500 : 500,
                      fontWeight:{
                        xs:"400",
                        md:"500"
                      },
                      lineHeight:
                        expanded === `panel${index + 1}` ? "160%" : "normal",
                      width: "100%",
                    }}
                  >
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Display either steps or an answer */}
                  {faq.steps ? (
                    <ul style={{ listStyle: "none", paddingLeft: "0%" }}>
                      {faq.steps.map((step, i) => (
                        <li
                          key={i}
                          style={{
                            color: "#000",
                            fontFamily: "Poppins !important",
                            width: "100%",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            marginBottom: "2%",
                          }}
                          className="faq-item-answer"
                        >
                          Step {i + 1} : {step}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <Typography
                      sx={{
                        color: "#000000",
                        fontFamily: "Poppins !important",
                        width: "100%",
                        fontStyle: "normal",
                        fontSize:{
                          xs:"12px !important",
                          md:"14px !important"
                        },
                        fontWeight: 400,
                        lineHeight: "160%",
                      }}
                      className="faq-item-answer"
                    >
                      {faq.answer}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FaqSection;
