import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import appLogo from "../../assets/images/appLogo.svg";
// import { HeadPhoneSvg } from "../Header/HeadPhoneSvg";
// import { FacebookSvg } from "./FacebookSvg";
// import { InstagramSvg } from "./InstagramSvg";
import { Link, useNavigate } from "react-router-dom";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import useMobile from "../../customHook/useMobile";
import location from "../../assets/images/location.svg"
import link from "../../assets/images/link.svg"
import applogomobile from "../../assets/images/applogo-mobile.svg"
import instagram from "../../assets/images/Instagram.svg"
import facebook from "../../assets/images/Facebook.svg"
import headphone from "../../assets/images/headphone.svg"
import "./style.css";

const Footer = () => {
  const navigate = useNavigate();
  const { createCustomerEventLog } = useCustomerEventLogs();
  const isMobile = useMobile();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        background: "#FFF6FC",
      }}
    >
      <Box sx={{
        padding: {
          xs: "4% 6% 2% 6%",
          md: "2% 6% 2% 6%"
        }
      }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <img
            src={applogomobile}
            alt="makemywedding"
            className="app-footerlogo-mobile"
            style={{ cursor: "pointer" }}
            onClick={handleLogoClick}
          />
          <Box sx={{
            display: {
              xs: "block",
              md: "none"
            }
          }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography component="a"
                href="https://www.instagram.com/makemyweddingofficial/?igsh=MWhjZHpyb3h3bTJyZA%3D%3D&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img style={{ height: "32px", width: "32px" }} src={instagram} alt="" />
              </Typography>
              <Typography component="a"
                href="https://www.facebook.com/profile.php?id=61565878822490"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img style={{ height: "32px", width: "32px" }} src={facebook} alt="" />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
            padding: "1rem 0"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Box>
              <Typography
                component="a"
                sx={{
                  color: "#000000",
                  fontFamily: "Poppins !important",
                  fontSize: {
                    md: "14px",
                    xs: "12px",
                  },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  textDecoration:"none"

                }}
                href="tel:+91 8939402236"
                onClick={() => {
                  createCustomerEventLog({
                    event_name: `cliked_on_contact_us_${isMobile ? "mobile" : "desktop"}`,
                    event_type: "click_event"
                  })
                }}
              >
                <img src={headphone} alt="" />
                Contact us
              </Typography>
            </Box>
            <Box>
              <Link
                to="/terms-and-conditions"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontFamily: "Poppins !important",
                    fontSize: {
                      md: "14px",
                      xs: "12px",
                    },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Terms & Conditions
                </Typography>
              </Link>
            </Box>

            <Box>
              <Link
                to="/privacy-policy"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontFamily: "Poppins !important",
                    fontSize: {
                      md: "14px",
                      xs: "12px",
                    },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Privacy Policy
                </Typography>
              </Link>
            </Box>


          </Box>
        </Box>
        {/* for desktop */}

        <Box sx={{
          display: {
            xs: "none",
            md: "block"
          }
        }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <img
                src={appLogo}
                alt="logo"
                className="appFooterLogo"
                width={"100%"}
                style={{ cursor: "pointer" }}
                onClick={handleLogoClick}
              />
            </Box>
            <Box>
              <Link
                to="/terms-and-conditions"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontFamily: "Poppins !important",
                    fontSize: {
                      md: "14px",
                      xs: "12px"
                    },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Terms & conditions
                </Typography>
              </Link>
            </Box>
            <Box>
              <Link
                to="/privacy-policy"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontFamily: "Poppins !important",
                    fontSize: {
                      md: "14px",
                      xs: "12px"
                    },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Privacy Policy
                </Typography>
              </Link>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#000000",
                  fontFamily: "Poppins !important",
                  fontSize: {
                    md: "14px",
                    xs: "12px"
                  },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                Contact us:
                <Typography component="span"
                  sx={{
                    color: "#000000",
                    fontFamily: "Poppins !important",
                    fontSize: {
                      md: "14px",
                      xs: "12px"
                    },
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  +91 8939402236
                </Typography>
              </Typography>
            </Box>
            <Box sx={{
              display: {
                xs: "none",
                md: "block"
              }
            }}>
              <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography component="a"
                href="https://www.instagram.com/makemyweddingofficial/?igsh=MWhjZHpyb3h3bTJyZA%3D%3D&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img style={{ height: "32px", width: "32px" }} src={instagram} alt="" />
              </Typography>
              <Typography component="a"
                href="https://www.facebook.com/profile.php?id=61565878822490"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img style={{ height: "32px", width: "32px" }} src={facebook} alt="" />
              </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography sx={{
            fontSize: "14px", fontWeight: "400",
            fontFamily: "Poppins !important",
            padding: "2% 0 2% 0",
            lineHeight: "22px",
            display: {
              xs: "none",
              md: "block"
            }
          }}>
            Make My Wedding simplifies wedding planning! Our dedicated Relationship Managers connect you with top vendors across categories like photographers, caterers, decorators, etc and negotiate the best deals. Get high-quality services and save time & money, all with a personalized touch.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "20px" }}>
          <Typography sx={{
            fontSize: "14px", fontWeight: "400",
            fontFamily: "Poppins !important",
            // padding: "0 0 1% 0",
            display: "flex",
            alignItems: {
              md: "center",
              xs: "flex-start"
            }
          }}>
            <Typography sx={{
              display: "flex", alignItems: "center", fontSize: {
                md: "14px",
                xs: "12px"
              }, fontWeight: "400",
              fontFamily: "Poppins !important",
              lineHeight: "22px"
            }} component="img" src={location} />    7th floor, RR tower III, Thiru Vi Ka Industrial Estate, SIDCO Industrial Estate, Guindy, Chennai, Tamil Nadu 600032</Typography>
          <Typography sx={{ display: "flex", alignItems: "center", gap: "6px", color: "#000000" }} component="a" href="https://g.co/kgs/B5bPsfF"><Typography component="img" src={link} /> https://g.co/kgs/B5bPsfF</Typography>
        </Box>

        <Box sx={{ padding: "1.5rem 0px" }}>
          <Box sx={{ border: "1px solid #FFCECE" }} />
        </Box>

        <Typography
          variant="h5"
          sx={{
            color: "#000",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "21px",
            opacity: "0.6",
            // padding: { xs: "5% 0%", sm: "2% 0%" },
          }}
        >
          © Copyright 2024 MakeMyWedding.com (Matrimony.com Group) - All Rights
          Reserved
        </Typography>
      </Box>
    </Box>
  );
};
export default Footer;
