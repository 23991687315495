import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  Button,
  Link, // Import Link for the hyperlink
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import { CREATE_MMW_CUSTOMER_FOLLOWUPS, CREATE_MMW_CUSTOMER_QUERY, CREATE_MMW_CUSTOMER_TRACKING, createEnquiry } from "../../apollo/createEnquiry";
import { UPDATE_MMW_CUSTOMER_QUERY, updateEnquiry } from "../../apollo/updateEnquiry";
import { getEnquiryDataByCustomerId, getEnquiryDataUsingMobileNumber } from "../../apollo/getEnquiryData";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import useMobile from "../../customHook/useMobile";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_OTP } from "../../apollo/get-otp";
import { VERIFY_OTP } from "../../apollo/verify-otp";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Send_Email_Notification } from "../../apollo/emailNotification";

interface Enquiry {
  id: number;
  name: string;
  phone: string;
  is_otp_verified: number;
  created_at: string | null;
  updated_at: string | null;
  __typename: string;
}

const OTPModal: React.FC<{
  open: boolean;
  onClose: () => void;
  mobileNumber: string;
  name: string;
  utmParams: any;
  setIsEditFormModalOpen?: any
  setEditForm?: any
}> = ({ open, onClose, mobileNumber, name, utmParams, setIsEditFormModalOpen, setEditForm }) => {

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [resendTimer, setResendTimer] = useState(180);
  // const [showResendLink, setShowResendLink] = useState(false);
  const [otpIncorrect, setotpIncorrect] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(open);
  const [createEnquiryRes, setCreateEnquiryRes] = useState<Enquiry>({
    id: 0,
    name: "",
    phone: "",
    is_otp_verified: 0,
    created_at: null,
    updated_at: null,
    __typename: "",

  });
  const { createCustomerEventLog } = useCustomerEventLogs();
  const isMobile = useMobile();
  const navigate = useNavigate()
  const currentDate = new Date().toISOString();
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "PROD";

  //send otp
  const [requestOtp] = useLazyQuery(GET_OTP, {
    fetchPolicy: 'network-only', // Important: Don't use cache
    onCompleted: (data: any) => {
      console.log("otp sent successfully")
    },
    onError: (error: any) => {
      console.log("Invalid phone number", error.message)
    }
  });

  //Send Email Notification
  const [sendNotification] = useMutation(Send_Email_Notification);

  const [updateMMWCustomerQuery] = useMutation(UPDATE_MMW_CUSTOMER_QUERY);
  const [createMMWCustomerTracking] = useMutation(CREATE_MMW_CUSTOMER_TRACKING);
  const [createMMWCustomerFollowups] = useMutation(CREATE_MMW_CUSTOMER_FOLLOWUPS);
  const [createMMWCustomerQuery] = useMutation(CREATE_MMW_CUSTOMER_QUERY, {
    onCompleted(data) {
      if (data?.createOneMmw_customer_query?.id) {
        const customerId = parseInt(localStorage?.getItem('customerId') as any || 0)
        createMMWCustomerTracking({
          variables: {
            data: {
              mmw_customer: {
                connect: {
                  id: customerId
                }
              },
              customer_query_id: data?.createOneMmw_customer_query?.id,
              utm_tracking_info: {
                utm_source: utmParams?.utm_source,
                utm_medium: utmParams?.utm_medium,
                utm_campaign: utmParams?.utm_campaign,
              },
            }
          }
        })
        createMMWCustomerFollowups({
          variables: {
            data: {
              mmw_customer_query: {
                connect: {
                  id: data?.createOneMmw_customer_query?.id
                }
              },
              mmw_config_customer_query_stage: {
                connect: {
                  id: 1
                }
              },
              mmw_customer: {
                connect: {
                  id: customerId
                }
              },
            }
          }
        })
      }
    },
  });

  //verify otp

  const [verifyOtp] = useLazyQuery(VERIFY_OTP, {
    fetchPolicy: 'network-only', // Important: Don't use cache
    async onCompleted(data) {
      if (data?.loginWithOTP) {
        // createEnquiryRes &&
        //   createEnquiryRes.id &&
        //   (await updateEnquiry(createEnquiryRes.id, name, 1));
        setShowOTPModal(false);
        createCustomerEventLog({
          event_name: `verify_otp_success_${isMobile ? "mobile" : "desktop"}`,
          event_type: "click_event"
        })
        navigate("/thank-you")
      }
    },
    onError: (error) => {
      setotpIncorrect(true);
      setOtp(["", "", "", ""]);
      console.error("Error verifying OTP:", error);
      createCustomerEventLog({
        event_name: `verify_otp_failed_${isMobile ? "mobile" : "desktop"}`,
        event_type: "click_event"
      })
    }
  });

  useEffect(() => {
    // Fetch OTP when component mounts
    const fetchOtp = async () => {
      try {
        // const deviceId = sessionStorage.getItem("deviceId");
        // const devicePlatform = sessionStorage.getItem("devicePlatform");

        // Fetch existing enquiry data using the mobile number
        // const existingData = await getEnquiryDataUsingMobileNumber(mobileNumber);
        // if (existingData) {
        //   setCreateEnquiryRes(existingData);
        // } else {
        //   // Create a new enquiry if no existing data is found
        //   const createEnquiryApi = await createEnquiry(
        //     name,
        //     mobileNumber,
        //     0,
        //     utmParams?.utm_source,
        //     utmParams?.utm_medium,
        //     utmParams?.utm_campaign,
        //     utmParams?.adgroup,
        //     utmParams?.placement,
        //     utmParams?.device_model,
        //     utmParams?.device,
        //     utmParams?.match_type,
        //     utmParams?.keyword,
        //     utmParams?.campaign_id
        //   );
        //   setCreateEnquiryRes(createEnquiryApi);
        // }

        // Request OTP
        const userData = await requestOtp({
          variables: {
            credential: {
              appType: "MOBILE",
              otpType: "LOGIN",
              userId: {
                name,
                phone: mobileNumber,
                source: "MMW",
                type: "PHONE_NUMBER",
                origin: "CUSTOMER",
                country: "INDIA",
              },
            },
          },
        });
        localStorage.setItem("customerId", userData?.data?.getOTP?.user);

        const isEnquiryAlreadyExist = await getEnquiryDataByCustomerId(userData?.data?.getOTP?.user)
        if (!isEnquiryAlreadyExist?.length) {
          createMMWCustomerQuery({
            variables: {
              data: {
                mmw_customer: {
                  connect: {
                    id: userData?.data?.getOTP?.user
                  }
                },
                status: 1,
                mmw_config_customer_query_source: {
                  connect: {
                    id: 1

                  }
                },
                mmw_config_customer_query_stage: {
                  connect: {
                    id: 1
                  }
                }
              }
            }
          })
        } else {
          updateMMWCustomerQuery({
            variables: {
              data: {
                updated_at: {
                  set: currentDate
                },
              },
              where: {
                id: isEnquiryAlreadyExist[0].id
              }
            }
          })
        }

        // send email notification
        await sendNotification({
          variables: {
            input: {
              notificationBody: {
                emailBody: {
                  templateKey: "MMW_ENQUIRY_EMAIL",
                  subject: `MakeMyWedding New Lead : ${name}`,
                  body: {
                    name: name,
                    phone: mobileNumber,
                  }
                }
              },
              source: "MMW",
              to: isProduction ?
                "care@makemywedding.com,karthick.manivannan@matrimony.com"
                : 'karthick.manivannan@matrimony.com',
              type: "EMAIL"
            }
          },
          fetchPolicy: "no-cache",
        });

      } catch (error) {
        console.error("Error fetching OTP:", error);
      }
    };




    if (open) {
      fetchOtp();
      // Fetch OTP only when the modal is open
      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            // setShowResendLink(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    } else {
      // setShowResendLink(false);
      // Hide resend link when modal is closed
    }
  }, [open, mobileNumber]);

  const handleResendOtp = () => {
    //   const deviceId = sessionStorage.getItem("deviceId");
    //   const uniqueDeviceId = sessionStorage.getItem("deviceId");
    //   const devicePlatform = sessionStorage.getItem("devicePlatform");
    requestOtp({
      variables: {
        credential: {
          appType: "MOBILE",
          otpType: "LOGIN",
          userId: {
            name,
            phone: mobileNumber,
            source: "MMW",
            type: "PHONE_NUMBER",
            origin: "CUSTOMER",
            country: "INDIA",
          }
        }
      },
    }).then(() => {
      // setShowResendLink(false);
      // Hide resend link
      setResendTimer(180);
      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            // setShowResendLink(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }).catch((error) => {
      console.error("Error while requesting OTP:", error);
    })
  };

  const handleOtpChange = (index: number, value: string) => {
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Only move focus for single-digit entry, not on pasting multiple digits
      if (value !== "" && value.length === 1 && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }

      // Move focus to the previous input if backspace is pressed
      if (value === "" && index > 0) {
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) prevInput.focus();
      }
    }
  };

  const handleVerifyOtp = async () => {
    // const deviceId = sessionStorage.getItem("deviceId");
    // const uniqueDeviceId = sessionStorage.getItem("deviceId");
    // const devicePlatform = sessionStorage.getItem("devicePlatform");
    createCustomerEventLog({
      event_name: `clicked_on_verify_otp_${isMobile ? "mobile" : "desktop"}`,
      event_type: "click_event"
    })
    try {
      await verifyOtp({
        variables: {
          credential: {
            // appType: "DESKTOP",
            appType: "MOBILE",
            otp: otp.join(""),
            otpType: "LOGIN",
            userId: {
              phone: mobileNumber,
              source: "MMW",
              type: "PHONE_NUMBER",
              origin: "CUSTOMER"
              // origin: "VENDOR"
            }
          }
        }
      });
    } catch (error) {
      console.log("not verified", error)
    }
  };

  const closeModal = (type: string) => {
    if (type === "editMobileNumber") {
      setEditForm({
        name: name,
        mobileNumber: mobileNumber
      });
      onClose();
      if (setIsEditFormModalOpen) {
        setIsEditFormModalOpen(true);
      }
    }
  };

  const minutes = Math.floor(resendTimer / 60);
  const seconds = resendTimer % 60;

  return (
    <>
      {showOTPModal && (
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
            sx: {
              borderRadius: "20px", // Add borderRadius to the Paper element
              margin: "unset",
              width: "352px",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "auto",
              width: "100%",
              maxWidth: "352px",
            }}
          >
            {/* Close button */}
            <Box sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end"
            }}>
              <IconButton
                onClick={async () => {
                  onClose();
                  createCustomerEventLog({
                    event_name: `otp_popup_closed_${isMobile ? "mobile" : "desktop"}`,
                    event_type: "click_event"
                  })
                }}
                sx={{ color: "#000000", p: 2 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Modal Content */}
            <DialogContent sx={{ paddingTop: "0 !important" }}>
              <Typography
                variant="h6"
                textAlign="left"
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                OTP Verification
              </Typography>
              <Typography
                textAlign="left"
                mt={1}
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                We’ve sent a 4-digit code to
              </Typography>
              <Box sx={{ display: "flex", gap: "6px", width: { xs: "100%" } }}>
                <Typography
                  textAlign="left"
                  fontWeight="bold"
                  sx={{
                    width: "40%", fontSize: "14px",
                    color: "#2574DC",
                    textDecoration: "underline"
                  }}
                >
                  {mobileNumber}
                </Typography>
                <Box
                  onClick={() => closeModal("editMobileNumber")}
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "6px",
                  }}
                >
                  <Box
                    sx={{
                      // width: "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ModeEditIcon sx={{ color: "#2574DC", fontSize: '16px' }} />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-around" mt={3} mb={2}>
                {otp.map((digit, index) => (
                  <TextField
                    type="number"
                    autoComplete="off"
                    key={index}
                    id={`otp-input-${index}`} // Assigning a unique id for each input
                    variant="outlined"
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    inputProps={{
                      maxLength: 1,
                      style: {
                        borderRadius: "8px",
                        border: "1px solid rgba(0, 0, 0, 0.20)",
                        background: "rgba(255, 255, 255, 0.05)",
                        width: "48px",
                        height: "48px",
                        padding: "5px 8px",
                        fontSize: "22px",
                        textAlign: "center", // Centering the text
                      },
                      onPaste: (e) => {
                        const pastedData = e.clipboardData
                          .getData("text")
                          .trim();
                        if (pastedData.length === otp.length) {
                          // Ensure the pasted data matches the OTP length
                          const otpArray = pastedData.split("");

                          setOtp(otpArray); // Set the entire OTP state at once

                          otpArray.forEach((digit, i) => {
                            const input = document.getElementById(
                              `otp-input-${i}`
                            ) as HTMLInputElement;
                            if (input) input.value = digit; // Update each input with the pasted value
                          });

                          // Focus on the last input field
                          const lastInput = document.getElementById(
                            `otp-input-${otpArray.length - 1}`
                          );
                          if (lastInput) lastInput.focus();

                          e.preventDefault(); // Prevent default paste behavior
                        }
                      },
                      onKeyDown: (e) => {
                        if (e.key === "Backspace" && !otp[index]) {
                          const prevInput = document.getElementById(
                            `otp-input-${index - 1}`
                          ) as HTMLInputElement;
                          if (prevInput) prevInput.focus();
                        }
                      },
                    }}
                  />
                ))}
              </Box>

              {/* Resend Timer */}
              <Typography
                textAlign="left"
                mt={1}
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                {!otpIncorrect && resendTimer > 0 ? (
                  `Didn’t receive OTP? Resend in ${`${minutes.toString().padStart(2, "0")}:${seconds
                    .toString()
                    .padStart(2, "0")}`}`
                ) : !otpIncorrect ? (
                  <>
                    Didn’t receive OTP?{" "}
                    <Link
                      component="button"
                      onClick={handleResendOtp}
                      sx={{
                        color: "#BE142D",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        textDecoration: "underline",
                      }}
                    >
                      Resend OTP
                    </Link>
                  </>
                ) : null}
              </Typography>
              {otpIncorrect ? (
                <Typography
                  sx={{
                    color: "#EF2965",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Please enter a valid OTP
                </Typography>
              ) : null}
              {/* Verify OTP Button */}
              <Box textAlign="center" mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={otp.some((digit) => !digit)}
                  onClick={handleVerifyOtp}
                  sx={{
                    textTransform: "none",
                    fontFamily: "Poppins",
                    display: "flex",
                    width: "100%",
                    height: "48px",
                    padding: "10px 24px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                    flexShrink: 0,
                    borderRadius: "12px",

                    // border: `1px solid ${otp.some((digit) => !digit) ? "" : "#B37839"
                    //   }`,
                    background: otp.some((digit) => !digit)
                      ? "#E6E6E6"
                      : "#BE142D",
                  }}

                >
                  Verify
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      )
      }
    </>
  );
};

export default OTPModal;
