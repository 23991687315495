import { gql } from "@apollo/client";
import client from "./apolloClient";

// Define the mutation as a constant
const UPDATE_ENQUIRY_MUTATION = gql`
    mutation UpdateOneMakemywedding_enquiries(
        $data: Makemywedding_enquiriesUpdateInput!
        $where: Makemywedding_enquiriesWhereUniqueInput!
    ) {
        updateOneMakemywedding_enquiries(data: $data, where: $where) {
            id
            name
            phone
            is_otp_verified
            created_at
            updated_at
        }
    }
`;

export const UPDATE_MMW_CUSTOMER_QUERY = gql`
    mutation UpdateOneMmw_customer_query(
        $data: Mmw_customer_queryUpdateInput!
        $where: Mmw_customer_queryWhereUniqueInput!
    ) {
        updateOneMmw_customer_query(data: $data, where: $where) {
            id
        }
    }
`;

// Reusable function to call the mutation
export const updateEnquiry = async (
    id: number,
    name: String,
    isOtpVerified: boolean | number
) => {
    try {
        // Define the mutation variables
        const variables = {
            data: {
                name: {
                    set: name,
                },
                updated_at: {
                    set: new Date(),
                },
                is_otp_verified: {
                    set: isOtpVerified,
                },
            },
            where: {
                id: id,
            },
        };

        // Execute the mutation using Apollo Client
        const result = await client.mutate({
            mutation: UPDATE_ENQUIRY_MUTATION,
            variables,
        });

        // Return the result or data as needed
        return result.data.updateOnemakemywedding_enquiries;
    } catch (error) {
        console.error("Error update enquiry:", error);
        throw new Error("Failed to update enquiry");
    }
};
