import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import Header from "../Header/Header";
import success from "../../assets/images/success.svg"
import "./style.css"
import { useNavigate } from "react-router-dom";

const ThankyouPage: React.FC = () => {
  const navigate = useNavigate();


  useEffect(() => {
    //executing first script
    const firstScript = document.createElement('script');
    firstScript.textContent = `console.log("first script");gtag('event', 'conversion', {'send_to': 'AW-16756272083/hxarCMS41eIZENPXgbY-'});`
    firstScript.onload = () => { console.log("first script loaded") }
    document.head.appendChild(firstScript)
    //executing second script
    const secondScript = document.createElement('script');
    secondScript.textContent = `function gtag_report_conversion(url) {
 var callback = function () {
if (typeof(url) != 'undefined') {
window.location = url;
}
};
//  gtag('event', 'conversion', {
 gtag_report_conversion('event', 'conversion', {
'send_to': 'AW-16756272083/hxarCMS41eIZENPXgbY-',
'event_callback': callback
 });
 return false;
}`

    secondScript.onload = () => { console.log("second script loaded") }
    document.head.appendChild(secondScript)
    return () => {
      document.head.removeChild(firstScript)
      document.head.removeChild(secondScript)
    }
  }, [])

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Header />
      </Box>
      <Box className="thank-you-container"
        sx={{
          background: "#FFF6F6",
          height: {
            md: "85vh",
            xs: "100vh"
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box sx={{
          position: {
            xs: "relative",
            md: "unset"
          },
          background: "#FFFFFF",
          boxShadow: {
            md: "0px 6px 24px 0px #0000001F",
            xs: "none"
          },
          width: "750px",
          height: {
            md: "460px",
            xs: "100%"
          },
          borderRadius: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          gap: "16px",
          pb: {
            xs: 8,
            md: 0
          }
        }}>
          {/* <Box sx={{display:"flex", }}> */}
          <img className="success-img" src={success} alt="" />
          <Typography sx={{ fontSize: "24px", fontWeight: "600", fontFamily: "Poppins !important" }}>Thank you!</Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: "400", fontFamily: "Poppins !important" }}>Your request has been submitted! <br />
            We will call you back shortly.</Typography>
          {/* </Box> */}
          <Box sx={{
            position: "absolute", bottom: "60px", display: {
              md: "none",
              xs: "block"
            }
          }}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "300px",
                height: "52px",
                borderRadius: "12px",
                background: "#BE142D",
                textTransform: "none",
                color: "#FFF",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "500",
              }}
              onClick={() => {
                navigate("/")
              }}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ThankyouPage;
