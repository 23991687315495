import {Box, Stack, Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import appLogo from "../../assets/images/appLogo.svg";
import callIcon from "../../assets/images/callIcon.png";
import { useNavigate } from "react-router-dom";
import useMobile from "../../customHook/useMobile";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import callLogo from "../../assets/images/calllogo.svg"
import applogomobile from "../../assets/images/applogo-mobile.svg"
import "./style.css";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { createCustomerEventLog } = useCustomerEventLogs();
  const isMobile = useMobile();


  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <Toolbar disableGutters>
      <Box sx={{ flexGrow: 1, background: "#FFF6F6" }}>
        <Grid
          container
          // spacing={2}
          sx={{ justifyContent: "space-between", alignItems: "center", pl: "6%", pr: "4%" }}
        >
          {/* Logo and Title */}
          <Grid size={{ xs: 7, sm: 6 }} sx={{ mt: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid size={{ xs: 10, sm: 6 }}>
                <img
                  src={appLogo}
                  alt="makemywedding"
                  className="appLogo"
                  style={{ cursor: "pointer" }}
                  onClick={handleLogoClick}
                />
                <img
                  src={applogomobile}
                  alt="makemywedding"
                  className="applogo-mobile"
                  style={{ cursor: "pointer" }}
                  onClick={handleLogoClick}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Contact Us Section */}
          <Grid size={{ xs: 4, sm: 6 }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <Box
                sx={{
                  background: "#FFE8E8",
                  borderRadius: "8px",
                  height: "40px",
                  width: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={callLogo} alt="" style={{ height: "16px", width: "16px" }} />
              </Box>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  sx={{
                    textDecorationLine: "none",
                    fontFamily: "Poppins !important",
                    fontSize: { xs: "14px", md: "14px" },
                    color: "#000000",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Contact us
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  sx={{
                    color: "#000000",
                    fontFamily: "Poppins !important",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    
                  }}
                >
                  +91 8939402236
                </Typography>
              </Stack>
            </Stack>

            {/* for mobile */}
            <Box
              sx={{
                width: "100%",
                display: { xs: "flex", sm: "none" },
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <a href="tel:+918939402236"
                className="callCta"
              >
                <img src={callIcon} alt="Phone Icon" />
                <Typography
                  sx={{
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                  onClick={() => {
                    createCustomerEventLog({
                      event_name: `cliked_on_contact_us_${isMobile ? "mobile" : "desktop"}`,
                      event_type: "click_event"
                    })
                  }}

                >
                  Contact us
                </Typography>
              </a>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Toolbar>
  );
};

export default Header;
