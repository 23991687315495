import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PAPData } from "./PAPData";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const PrivacyAndPolicy: React.FC = () => {
  const navigate = useNavigate();

  // Handle back button click
  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Header />
      </Box>
      <Box
        sx={{ flexGrow: 1, width: { xs: "100%", md: "80%" }, margin: "auto" }}
      >
        {/* AppBar with custom styles */}
        <AppBar
          position="sticky"
          sx={{
            backgroundColor: "#FFF",
            boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.04)",
            display: { xs: "block", md: "none" },
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleBackClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M15 6L9 12L15 18"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Privacy Policy
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            margin: "1% 0%",
            padding: "2% 0%",
          }}
        >
          {" "}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBackClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15 6L9 12L15 18"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 500,
              fontStyle: "normal",
              lineHeight: "normal",
              marginLeft: "3%",
              display: "flex",
              alignItems: "center",
            }}
          >
            Privacy Policy
          </Typography>
        </Box>

        {/* Main content */}
        <Box sx={{ padding: { xs: 3, md: "2% 0%" } }}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "normal",
            }}
          >
            This privacy policy applies to your use of the Makemywedding.com
            Website located at{" "}
            <a style={{ color: "blue" }} href="/">
              https://makemywedding.com
            </a>{" "}
            (hereafter known as MMW/Website),but not does apply to any third
            party sites that may be linked to the Website, or any relationships
            you may have with the businesses listed on MMW. The terms "we",
            "us", and "MMW" refer to Makemywedding.com and the terms "you" and
            "your" refer to you, as a user of MMW. The term "personal
            information" means information that you provide to us which
            personally identifies you to be contacted or identified, such as
            your name, phone number, email address, and any other data that is
            tied to such information. By using MMW, you provide your explicit
            consent to us for collecting / storing / accessing / sharing your
            personal information and agree to be bound by terms of this privacy
            policy.
          </Typography>
          {PAPData.map((item, index) => (
            <React.Fragment key={index}>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: { xs: "12px", md: "14px" },
                  fontWeight: 500,
                  fontStyle: "normal",
                  lineHeight: "normal",
                  marginTop: "3%", // adjust margin based on index
                }}
              >
                {index + 1}. {item.heading}:
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: { xs: "12px", md: "14px" },
                  fontWeight: 400,
                  fontStyle: "normal",
                  lineHeight: "normal",
                  margin: "2% 0%",
                }}
                dangerouslySetInnerHTML={{ __html: item.paragraph }} // Use dangerouslySetInnerHTML here
              />
              {item.paragraph2 !== null ? (
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    margin: "2% 0%",
                  }}
                >
                  {item.paragraph2}
                </Typography>
              ) : item.paragraph2List ? (
                <Box
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    margin: "2% 0%",
                    textDecoration: "none", // Optional: to remove underline
                  }}
                >
                  {item.paragraph2List.map((item, index) => (
                    <Box key={index} sx={{ marginBottom: "1rem" }}>
                      {Object.entries(item).map(([key, value], index) => (
                        <React.Fragment key={index}>
                          <Typography
                            key={index} // Use the key as a unique identifier
                            sx={{
                              color: "#000",
                              fontFamily: "Poppins",
                              fontSize: { xs: "12px", md: "14px" },
                              fontWeight: 500,
                              fontStyle: "normal",
                              lineHeight: "normal",
                              margin: "2% 0%",
                            }}
                          >
                            {key}
                          </Typography>
                          <Typography
                            key={key} // Use the key as a unique identifier
                            sx={{
                              color: "#000",
                              fontFamily: "Poppins",
                              fontSize: { xs: "12px", md: "14px" },
                              fontWeight: 400,
                              fontStyle: "normal",
                              lineHeight: "normal",
                              marginBottom: "3%",
                            }}
                          >
                            {value}
                          </Typography>
                        </React.Fragment>
                      ))}
                    </Box>
                  ))}
                </Box>
              ) : null}
            </React.Fragment>
          ))}
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 500,
              fontStyle: "normal",
              lineHeight: "normal",
              marginTop: "3%", // adjust margin based on index
            }}
          >
            Change of Privacy Policy
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "normal",
              margin: "2% 0%",
            }}
          >
            We may change this Privacy Policy from time to time without any
            notice to you. However, changes will be updated in the Privacy
            Policy page.
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "normal",
              marginTop: "3%", // adjust margin based on index
            }}
          >
            <span
              style={{
                color: "#000",
                fontFamily: "Poppins",
                fontWeight: 500,
                fontStyle: "normal",
                lineHeight: "normal",
                marginTop: "3%", // adjust margin based on index
              }}
            >
              How to address your Grievance :
            </span>{" "}
            <span>
              The Grievance officer :Mr. Gurusamy B. Address; Matrimony.com
              Limited, Contact details: 94, TVH Belciaa Towers,Tower-2, 5th
              Floor, MRC Nagar, Chennai 600 028{" "}
            </span>
            <a href="mailto:grievanceofficer@matrimony.com">
              grievanceofficer@matrimony.com
            </a>
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "normal",
              margin: "2% 0%",
            }}
          >
            The Grievance officer shall be available between 10 am to 6 pm IST
            from Monday to Saturday excluding Sunday's and Public Holidays in
            India.
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "normal",
              margin: "2% 0%",
            }}
          >
            The Grievance officer is appointed as per Section 5 (9) of the
            Information Technology (Reasonable Security & Procedures and
            Sensitive Personal data or Information) Rule, 2011.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Footer />
      </Box>
    </>
  );
};

export default PrivacyAndPolicy;
