import { gql } from "@apollo/client";
import client from "./apolloClient";

// Define the query as a constant
// const GET_ENQUIRY_DATA = gql`
//     query FindFirstMakemywedding_enquiries(
//         $where: Makemywedding_enquiriesWhereInput
//     ) {
//         findFirstMakemywedding_enquiries(where: $where) {
//             id
//             name
//             phone
//             is_otp_verified
//             created_at
//             updated_at
//         }
//     }
// `;

const GET_CUSTOMER_CUSTOMER_QUERY = gql`
    query FindFirstMmw_customer($where: Mmw_customerWhereInput) {
        findFirstMmw_customer(where: $where) {
            id
            mmw_customer_query {
                id
            }
            mmw_customer_contact {
                is_phone_verified
            }
        }
    }
`;

const GET_ENQUIRY_DATA_BY_CUSTOMER_ID = gql`
    query FindManyMmw_customer_query($where: Mmw_customer_queryWhereInput) {
        findManyMmw_customer_query(where: $where) {
            id
        }
    }
`;

// Reusable function to call the query
export const getEnquiryDataUsingMobileNumber = async (mobile: string) => {
    try {
        // Define the mutation variables
        const variables = {
            where: {
                phone: {
                    equals: mobile,
                },
            },
        };

        // Execute the query using Apollo Client
        const result = await client.mutate({
            mutation: GET_CUSTOMER_CUSTOMER_QUERY,
            variables,
        });

        // Return the result or data as needed
        return result.data.findFirstMmw_customer;
    } catch (error) {
        console.error("Error update enquiry:", error);
        throw new Error("Failed to update enquiry");
    }
};

export const getEnquiryDataByCustomerId = async (customerId: number) => {
    try {
        // Define the mutation variables
        const variables = {
            where: {
                customer_id: {
                    equals: customerId,
                },
            },
        };

        // Execute the query using Apollo Client
        const result = await client.mutate({
            mutation: GET_ENQUIRY_DATA_BY_CUSTOMER_ID,
            variables,
        });

        // Return the result or data as needed
        return result.data.findManyMmw_customer_query;
    } catch (error) {
        console.error("Error update enquiry:", error);
        throw new Error("Failed to update enquiry");
    }
};
