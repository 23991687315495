import React, { useEffect } from "react";
// import VideoSection from "../VideoSection/VideoSection";
import PartnersSection from "../PartnersSection/PartnersSection";
import FaqSection from "../Faq/FaqSection";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import ReactGA from "react-ga4";
import { GOOGLE_TRACKING_ID } from "../../utils/constants";
import NewBannerSection from "../BannerSection/NewBannerSection";
import NewHowWorkSection from "../HowWorkSection/NewHowItWorkSection";
import Testimonial from "../Testimonial/Testimonial";
import VideoSection from "../VideoSection/VideoSection";
import "./style.css"

const Home: React.FC = () => {

  useEffect(() => {
    //api call for google analytics
    if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
      ReactGA.initialize(GOOGLE_TRACKING_ID);
      ReactGA.send({
        hitType: 'pageview', page: window.location.pathname
      })
    }
  }, [])



  return (
    <>
      <div className="home-wrapper">
        <Header />
        <NewBannerSection />
        <NewHowWorkSection />
        <PartnersSection />
        <VideoSection />
        <Testimonial />
        <FaqSection />
        <Footer />
      </div>
    </>
  );
};

export default Home;
