import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, from } from '@apollo/client';

// Create an HTTP link for your GraphQL endpoint
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_END_POINT,
});

// Create an Apollo Link to add the Authorization header
const authLink = new ApolloLink((operation, forward) => {
  // Get the Authorization token (it can be from localStorage, sessionStorage, or a global state)
  const token = "bWF0cmltb255Om1hdHJpbW9ueQ=="; // Replace with your token logic

  // Add the Authorization header to each request
  operation.setContext({
    headers: {
      Authorization: token ? `Basic ${token}` : "",
    },
  });

  return forward(operation);
});

// Combine the authLink and httpLink
const client = new ApolloClient({
  link: from([authLink, httpLink]), // Chain authLink and httpLink
  cache: new InMemoryCache(),
});

export default client;
