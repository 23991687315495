import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";

import Header from "../Header/Header";
import success from "../../assets/images/success.svg"

const ThankYouPageExisting: React.FC = () => {



  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Header />
      </Box>
      <Box className="thank-you-container"
        sx={{
          background: "#FFF6F6",
          height: "85vh",
          display:"flex",
          justifyContent:"center",
          alignItems:"center"
        }}
      >
        <Box sx={{
          background: "#FFFFFF",
          boxShadow: "0px 6px 24px 0px #0000001F",
          width:"750px",
          height:"460px",
          borderRadius:"24px",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          flexDirection:"column",
          textAlign:"center",
          gap:"16px"
        }}>
          {/* <Box sx={{display:"flex", }}> */}
          <img src={success} alt="" style={{
            height:"100px",
            width:"100px"
          }} />
          <Typography sx={{fontSize:"24px", fontWeight:"600", fontFamily:"Poppins !important"}}>Already request submitted!</Typography>
          <Typography sx={{fontSize:"16px", fontWeight:"400", fontFamily:"Poppins !important"}}>We have already received your request.
          <br />
          We will call you back shortly.</Typography>
          {/* </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default ThankYouPageExisting;
