import React from "react";
import { Typography, Box, Button } from "@mui/material";
import EnterYourDetailsModal from "../EnterYourDetailsModal/EnterYourDetailsModal";
import "./style.css";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import useMobile from "../../customHook/useMobile";
import notes from "../../assets/images/notes.svg"
import target from "../../assets/images/target.svg"
import rupee from "../../assets/images/rupee-sign.svg"
import shoppingbag from "../../assets/images/shopping-bag.svg"
import howitworks from "../../assets/images/howItWorks.png"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";





const NewHowWorkSection: React.FC = () => {


    const [isFormModalOpen, setIsFormModalOpen] = React.useState(false);
    const { createCustomerEventLog } = useCustomerEventLogs();
    const isMobile = useMobile();


    const handleOpenModal = () => {
        setIsFormModalOpen(true); // Explicitly set to true
    };



    const handleCloseModal = () => {
        setIsFormModalOpen(false); // Explicitly set to false
    };




    const handleClick = async () => {
        try {
            // Open the modal first
            handleOpenModal();

            // Log the first event
            await createCustomerEventLog({
                event_name: `click_on_request_call_back_HIW_${isMobile ? "mobile" : "desktop"}`,
                event_type: "click_event",
            });

            // Log the second event after the first one is completed
            await createCustomerEventLog({
                event_name: `enter_your_details_popup_mounted_${isMobile ? "mobile" : "desktop"}`,
                event_type: "click_event",
            });
        } catch (error) {
            console.error("Error in event logging:", error);
        }
    };


    return (
        <>
            <Box
                className="how-it-works-container"
            >
                <Box className="how-it-works-wrapper">
                    <Box className="how-it-works-left">
                        <Typography sx={{ fontSize: { xs: "24px", md: "40px" },
                         fontWeight: "600",
                          fontFamily: "Poppins !important",
                          paddingLeft:{
                            xs:"6%",
                            md:"0"
                          }
                           }}>
                            How it works?
                        </Typography>
                        <Box className="hiw-content">
                            {/* Add a container for the dotted line */}
                            <Box className="dotted-line-container">
                                <Box className="dotted-line"></Box>
                                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "2%" }}>
                                    <img src={notes} alt="" />
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "16px", md: "24px" },
                                                fontWeight: "600",
                                                fontFamily: "Poppins !important",
                                                lineHeight: { md: "36px", xs: "24px" },
                                            }}
                                        >
                                            Share your requirements
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "14px", md: "20px" },
                                                fontWeight: "400",
                                                fontFamily: "Poppins !important",
                                                color: "#242424",
                                                lineHeight: { md: "30px", xs: "21px" },
                                            }}
                                        >
                                            Share your event date, budget, location, venue & {!isMobile && <br />} vendor
                                            preferences with our Relationship Managers
                                        </Typography>
                                    </Box>
                                </Box>
                                {/* Repeat the above block for other sections */}
                                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "2%" }}>
                                    <img src={rupee} alt="" />
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "16px", md: "24px" },
                                                fontWeight: "600",
                                                fontFamily: "Poppins !important",
                                                lineHeight: { md: "36px", xs: "24px" },
                                            }}
                                        >
                                            Get Vendor recommendations
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "14px", md: "20px" },
                                                fontWeight: "400",
                                                fontFamily: "Poppins !important",
                                                color: "#242424",
                                                lineHeight: { md: "30px", xs: "21px" },
                                            }}
                                        >
                                            We'll recommend trusted and reliable vendors,{!isMobile && <br />} handle price
                                            negotiations to get you the best deals
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "2%" }}>
                                    <img src={target} alt="" />
                                    <Box>
                                        <Typography sx={{ fontSize: { xs: "16px", md: "24px" }, fontWeight: "600", fontFamily: "Poppins !important", lineHeight: { md: "36px", xs: "24px" } }}>Receive guidance from start to finish</Typography>
                                        <Typography sx={{ fontSize: { xs: "14px", md: "20px" }, fontWeight: "400", fontFamily: "Poppins !important", color: "#242424", lineHeight: { md: "30px", xs: "21px" } }}>
                                            Our Relationship Manager will provide personalised {!isMobile && <br />} support to you at every step, ensuring a seamless {!isMobile && <br />} wedding planning experience.</Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "2%" }}>
                                    <img src={shoppingbag} alt="" />
                                    <Box>
                                        <Typography sx={{ fontSize: { xs: "16px", md: "24px" }, fontWeight: "600", fontFamily: "Poppins !important", lineHeight: { md: "36px", xs: "24px" } }}>Unlock Exclusive Shopping Discounts</Typography>
                                        <Typography sx={{ fontSize: { xs: "14px", md: "20px" }, fontWeight: "400", fontFamily: "Poppins !important", color: "#242424", lineHeight: { md: "30px", xs: "21px" } }}>
                                            Enjoy special savings on wedding shopping such as {!isMobile && <br />} jewellery, apparels, electronics etc. with exclusive {!isMobile && <br />} discounts from popular brands.</Typography>
                                    </Box>
                                </Box>

                                {/* Add remaining sections similarly */}
                            </Box>
                            {/* {
                                !isMobile && */}
                               <Box sx={{
                                background:"#FFFFFF",
                                 position:{
                                    xs:"fixed",
                                    md:"unset"
                                },
                                bottom:"0",
                                zIndex:"10",
                                width:"100%",
                                padding:{
                                    xs:"4% 4% 0 4%",
                                    md:"0 0 0 0"
                                },
                                textAlign:{
                                    xs:"center",
                                    md:"unset"
                                }
                               }}>
                            <div className="consent-cta-btn" style={{ color: "#7B5E82", fontFamily: "Poppins", fontStyle: "normal", fontSize: "12px", fontWeight: "400" }}>*By clicking, I agree to the <Link style={{ color: "#7B5E82" }} to="/terms-and-conditions"><span>T&C</span></Link> and <Link style={{ color: "#7B5E82" }} to="/privacy-policy"><span>Privacy Policy</span></Link></div>
                               <Button
                                    variant="contained"
                                    sx={{
                                        textTransform: "none",
                                        //   display: "flex",
                                        width: {
                                            xs:"100%",
                                            md:"263px"
                                        },
                                        height: "52px",
                                        padding: "12px 24px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "4px",
                                        flexShrink: 0,
                                        borderRadius: "12px",
                                        border: "1px solid #B37839",
                                        background: "#BE142D",
                                        color: "#FFF",
                                        fontFamily: "Poppins",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "normal",
                                        marginTop: {
                                            xs: "10px",
                                            md: "40px"
                                        },
                                        marginBottom: {
                                            xs: "16px",
                                            md: "30px"
                                        },  
                                    }}
                                    endIcon={<ArrowForwardIcon />}
                                    onClick={() => {
                                        // handleOpenModal();
                                        handleClick()
                                    }}
                                >
                                    {isMobile? "Get FREE consultation":" Request call back"}
                                   
                                </Button>
                               </Box>
                            {/* } */}
                        </Box>

                    </Box>
                    <Box className="how-it-works-right">
                        <img src={howitworks} alt="" />
                    </Box>
                </Box>
            </Box>
            <EnterYourDetailsModal open={isFormModalOpen} setIsEditFormModalOpen={setIsFormModalOpen} onClos={handleCloseModal} />
        </>
    );
};

export default NewHowWorkSection;
