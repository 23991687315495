import { gql } from "@apollo/client";

export const GET_OTP = gql`
    query Query($credential: OTPCredentialInput!) {
        getOTP(credential: $credential){
            message,
                user
        }
    }
`;
